import { sanityImage } from '@/utils/sanityImage';
import { Box, Flex, Text } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import { ButtonLink } from '../ButtonLink';
import { MotionBox } from '../MotionBox';
import styles from './ImagesMatrixWithText.module.css';
import { ImagesMatrixWithTextProps } from './ImagesMatrixWithText.props';

const ImagesMatrixWithText: FC<ImagesMatrixWithTextProps> = ({swapOrdering=true,title,description,image,link}) => (
  <Flex>
    <Box bg="white" flex={1} order={swapOrdering ? 1 : 2}>
      <MotionBox
        initial={{ x: 10, opacity: 0 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ delay:0.3, duration: 0.5 }}
      >
        <Image
          className={styles.image}
          src={sanityImage(image)?.width(350).height(311).url()||''}
          alt={title||'Bar Restaurant'}
          width={350}
          height={311}
        />
      </MotionBox>
    </Box>
    <Box flex={1} bg="white" order={swapOrdering ? 2 : 1}>
      <Box p={{ base: '15px', md: '40px' }}>
        <MotionBox
          initial={{ x: -10, opacity: 0 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ delay:0.3, duration: 0.6 }}
        >
          <Text mb={{base: '10px',md:'30px'}} fontSize="1.5rem" as="h3" fontFamily="heading">{title}</Text>
        </MotionBox>
        <MotionBox
          initial={{ x: -10, opacity: 0 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ delay:0.3, duration: 0.6 }}
        >
          <Text mb={{base: '15px',md:'40px'}} as="p" color="blackAlpha.600">{description}</Text>
        </MotionBox>
        <MotionBox
          initial={{ x: -10, opacity: 0 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ delay:0.3, duration: 0.6 }}
        >
          <ButtonLink link={link} theme="blackColor"/>
        </MotionBox>
      </Box>
    </Box>
  </Flex>
);

export { ImagesMatrixWithText };
