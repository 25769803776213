import { FC } from 'react';
import styles from './VideoCover.module.css';
import { VideoCoverProps } from './VideoCover.props';

const VideoCover: FC<VideoCoverProps> = ({data}) => {
  if(data?.iframe){
    return(
      <div className={styles.videoCoverWrapper}>
        <iframe src={data?.iframe} title="Background video" />
      </div>
    );
  }

  return null;
};

export { VideoCover };
