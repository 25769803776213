import { ButtonLink } from '@/components/ButtonLink';
import { MotionBox } from '@/components/MotionBox';
import { useLocaleField } from '@/hooks/useLocaleField';
import { sanityImage } from '@/utils/sanityImage';
import { Box, Container, Flex, Text } from '@chakra-ui/react';
import { PortableText } from '@portabletext/react';
import Image from 'next/image';
import { FC } from 'react';
import styles from './Intro.module.css';
import { IntroProps } from './Intro.props';

const Intro: FC<IntroProps> = ({data}) => {
  const {getLocaleBlock,getLocaleField} = useLocaleField();

  return(
    <Box as="section" mt={{ base: '5rem', md:'8rem' }} className={styles.root}>
      <Container maxW={{ base: '1440px', md: '680p' }}>
        <Flex direction={{base:'column', md:'row'}} justifyContent="space-between">
          <Box order={{base:2,md:1}} flex="1" className={styles.images_col} flexBasis={{md:1}}>
            <MotionBox
              style={{height:'100%',position:'relative'}}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1}}
              viewport={{ once: true }}
              transition={{ delay: 0.2}}
            >
              <Image
                className={styles.vertical_image}
                src={sanityImage(data?.image)?.width(700).height(500).url()||''}
                alt={getLocaleField(data?.title)||'Intro'}
                width={700}
                height={500}
                sizes="(max-width: 768px) 370px, (max-width: 1200px) 706px, 33vw"
              />
            </MotionBox>
          </Box>
          <Box order={{base:1,md:2}} flex="1" mb={{base:'50px'}} >
            <Box p={{md:'0 50px', sm:'0 10px'}}>
              <MotionBox
                initial={{ t: 15, opacity: 0 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 , duration: 0.5, type: 'spring'}}
              >
                <Text as="h3"  className={styles.subtitle} fontSize="sm">{getLocaleField(data?.subtitle)}</Text>
              </MotionBox>
              <MotionBox
                initial={{ y: 30, opacity: 0 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 , duration: 1, type: 'spring'}}
              >
                <Text mb="4" letterSpacing="-0.1rem" as="h1" fontFamily="heading" fontSize="4xl">
                  {getLocaleField(data?.title)}
                </Text>
              </MotionBox>
              <Box className={styles.description_wrapper}>
                <MotionBox
                  initial={{ y: 10, lineHeight:'2rem', opacity: 0 }}
                  whileInView={{ y:0, opacity: 1, lineHeight:'1.8rem' }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.2 , duration: 1, type: 'spring'}}
                >
                  <PortableText value={getLocaleBlock(data?.description)} />
                </MotionBox>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export { Intro };
