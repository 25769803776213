import { Box, useMediaQuery } from '@chakra-ui/react';
import { FC, useCallback, useMemo, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import cn from 'classnames';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { SectionTitle } from '@/components/SectionTitle';
import { RoomsCarouselProps } from './RoomsCarousel.props';
import styles from './RoomsCarousel.module.css';
import { RoomCard } from '../RoomCard';

const RoomsCarousel: FC<RoomsCarouselProps> = ({ data }) => {
  const rooms = data?.rooms;
  const sectionTitle = data?.sectionTitle;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSmallerThan540] = useMediaQuery('(max-width: 540px)');
  const [needControllers, setNeedControllers] = useState(isSmallerThan540);

  const [sliderRef, slider] = useKeenSlider({
    optionsChanged: (_slider) => {
      // @ts-ignore
      if (rooms && rooms?.length > _slider?.options?.slides?.perView) {
        setNeedControllers(true);
      } else {
        setNeedControllers(false);
      }
    },
    slides: {
      perView: 4,
      spacing: 15,
    },
    breakpoints: {
      '(max-width: 1200px)': {
        slides: {
          perView: 3,
          spacing: 15,
        },
      },
      '(max-width: 768px)': {
        slides: {
          perView: 2,
          spacing: 15,
        },
      },
      '(max-width: 540px)': {
        slides: { perView: 1 },
        loop: true,
      },
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel);
    },
  });

  return (
    <div className={styles.roomsCarouselWrapper}>
      <Box className={styles.RoomsCarousel} as="section" mt={{ base: '6rem', md: '8rem' }}>
        <SectionTitle data={sectionTitle} />
        <Box mt={{ base: '2rem', md: '4rem' }} px="15px" position="relative" maxW="1800" mx="auto">
          <div ref={sliderRef} className="keen-slider">
            {rooms?.map((room, i) => (
              <div key={room?._id} className="keen-slider__slide">
                <RoomCard index={i} room={room} />
              </div>
            ))}
          </div>
          {slider && needControllers && (
            <>
              <button
                type="button"
                className={cn(styles.arrow, styles.arrow_left)}
                onClick={(e) => slider.current?.prev()}
              >
                <BsArrowLeft />
              </button>
              <button
                type="button"
                className={cn(styles.arrow, styles.arrow_right)}
                onClick={(e) => slider.current?.next()}
              >
                <BsArrowRight />
              </button>
            </>
          )}
        </Box>
        {slider && isSmallerThan540 && (
          <Box>
            <div className={styles.dots}>
              {[...Array(slider.current?.slides.length).keys()].map((idx) => (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <button
                  type="button"
                  key={idx}
                  onClick={() => {
                    slider.current?.moveToIdx(idx);
                  }}
                  className={styles.dot + (currentSlide === idx ? ` ${styles.dot_active}` : '')}
                />
              ))}
            </div>
          </Box>
        )}
      </Box>
    </div>
  );
};

export { RoomsCarousel };
