import { useLocaleField } from '@/hooks/useLocaleField';
import { Container, Divider, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { MotionBox } from '../MotionBox';
import styles from './SectionTitle.module.css';
import { SectionTitleProps } from './SectionTitle.props';

const SectionTitle: FC<SectionTitleProps> = ({data}) => {
  const {getLocaleField} =useLocaleField();

  if(data?.title){

    return(
      <div className={styles.sectionTitleWrapper}>
        <Container maxW="6xl">
          <MotionBox
            initial={{ y:20, opacity: 0 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            <Text
              as="h2"
              fontSize="3xl"
              fontFamily="heading"
              align="center"
              color="gray.800"
              mb={{base:'0.5rem', md: '1rem' }}
            >
              {getLocaleField(data?.title)}
            </Text>
          </MotionBox>
          <Divider position="relative" className={styles.divider} />
          <MotionBox
            initial={{ y: -20, opacity: 0 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <Text
              color="gray.800"
              align="center"
              as="p"
              fontSize="lg"
              lineHeight="1.8em"
              mt={{base:'0.5rem', md: '1rem' }}
            >
              {getLocaleField(data?.description)}
            </Text>
          </MotionBox>
        </Container>
      </div>
    );
  }

  return null;
};

export { SectionTitle };
