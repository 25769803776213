import { useKeenSlider } from 'keen-slider/react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import cn from 'classnames';
import { sanityImage } from '@/utils/sanityImage';
import Image from 'next/image';
import { SimpleCarouselProps } from './SimpleCarousel.props';
import styles from './SimpleCarousel.module.css';

const SimpleCarousel: FC<SimpleCarouselProps> = ({ images, width, height }) => {
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    loop: true,
  });

  const size = useMemo<{ width: number; height: number }>(() => {
    if (width && height) {
      return {
        width,
        height,
      };
    }

    return {
      width: 700,
      height: 620,
    };
  }, [width, height]);

  if (images?.length) {
    return (
      <div ref={sliderRef} className={cn('keen-slider', styles.sliderWrapper)}>
        {images.map((item) => (
          <div key={item?._key} className={cn('keen-slider__slide', styles.sliderItem)}>
            <Image
              className={styles.itemImage}
              src={sanityImage(item)?.width(size.width).height(size.height).url() || ''}
              alt={item?.asset?.altText || 'Slider image'}
              width={size.width}
              height={size.height}
              loading="eager"
            />
          </div>
        ))}
        {Array.isArray(images) && slider && (
          <>
            <button
              type="submit"
              className={cn(styles.arrow, styles.arrow_left)}
              onClick={(e) => slider.current?.prev()}
            >
              <BsArrowLeft />
            </button>
            <button
              type="submit"
              className={cn(styles.arrow, styles.arrow_right)}
              onClick={(e) => slider.current?.next()}
            >
              <BsArrowRight />
            </button>
          </>
        )}
      </div>
    );
  }

  return null;
};

export { SimpleCarousel };
