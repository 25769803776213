import { ImagesMatrixWithText } from '@/components/ImagesMatrixWithText';
import { MotionBox } from '@/components/MotionBox';
import { SectionTitle } from '@/components/SectionTitle';
import { SimpleCarousel } from '@/components/SimpleCarousel';
import { useLocaleField } from '@/hooks/useLocaleField';
import { Box, Container, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import styles from './BarRestaurant.module.css';
import { BarRestaurantProps } from './BarRestaurant.props';

const BarRestaurant: FC<BarRestaurantProps> = ({data}) => {
  const {getLocaleField} =useLocaleField();

  return(
    <Box
      py="50px"
      bg="primary-100"
      as="section"
      mt={{ base: '4rem', md: '8rem' }}
    >
      <SectionTitle data={data?.sectionTitle}/>
      <Container maxW="8xl" mt={{base: '2rem', md:'5rem'}}>
        <Flex flexDirection={{ base: 'column', md: 'row' }}>
          <Box flex={1} order={{ base: 2, md: 1 }}>
            <ImagesMatrixWithText
              title={getLocaleField(data?.imagesMatrix?.title1)}
              description={getLocaleField(data?.imagesMatrix?.description1)}
              image={data?.imagesMatrix?.image1}
              link={data?.imagesMatrix?.cta1}
            />
            <ImagesMatrixWithText
              title={getLocaleField(data?.imagesMatrix?.title2)}
              description={getLocaleField(data?.imagesMatrix?.description2)}
              image={data?.imagesMatrix?.image2}
              swapOrdering={false}
              link={data?.imagesMatrix?.cta2}
            />
          </Box>
          <Box flex={1} overflow="hidden" order={{ base: 1, md: 2 }}>
            <MotionBox
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1}}
              viewport={{ once: true }}
              transition={{ delay: 0.2, duration: 0.5 }}
              flex={1} order={{ base: 1, md: 2 }} mb={{ base: '15px', md: 0 }}
            >
              <SimpleCarousel images={data?.imagesMatrix?.slider} />
            </MotionBox>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export { BarRestaurant };
