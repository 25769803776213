import { sanityImage } from '@/utils/sanityImage';
import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import Image from 'next/image';
import { FC, useMemo } from 'react';
import { MotionBox } from '@/components/MotionBox';
import { PortableText } from '@portabletext/react';
import { useLocaleField } from '@/hooks/useLocaleField';
import Link from 'next/link';
import styles from './RoomCard.module.css';
import { RoomCardProps } from './RoomCard.props';

const RoomCard: FC<RoomCardProps> = ({ room, index }) => {
  const [isSmallerThan540] = useMediaQuery('(max-width: 540px)');
  const [isSmallerThan768] = useMediaQuery('(max-width: 768px)');
  const [isSmallerThan1200] = useMediaQuery('(max-width: 1200px)');

  const delay = 0.5 * index;

  const initialCardState = useMemo(() => {
    if (isSmallerThan540 && index === 0) {
      return { y: 20, opacity: 0 };
    }

    if (isSmallerThan768 && index < 2 && !isSmallerThan540) {
      return { y: 20, opacity: 0 };
    }

    if (isSmallerThan1200 && index < 3 && !isSmallerThan768) {
      return { y: 20, opacity: 0 };
    }

    if (!isSmallerThan1200) {
      return { y: 20, opacity: 0 };
    }

    return { y: 0, opacity: 1 };
  }, [isSmallerThan540, index, isSmallerThan1200, isSmallerThan768]);

  const { getLocaleField, getLocaleBlock } = useLocaleField();

  return (
    <MotionBox
      initial={initialCardState}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay, duration: 0.8, type: 'spring' }}
      flex="1"
      bg="white"
      borderWidth="1px"
      overflow="hidden"
    >
      <div style={{ height: 300, position: 'relative' }}>
        <Image
          src={sanityImage(room?.coverImage)?.width(300).url() || ''}
          alt={getLocaleField(room?.name) || 'Room image'}
          fill
        />
      </div>
      <Box p="6" pt="10">
        <Flex alignItems="baseline" justifyContent="space-between">
          <Box>
            <Box color="gray.500" fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase">
              <>
                {getLocaleField(room?.beds)}
                {room?.hasBalcony && <span> &bull; Balcony </span>}
                {/* {occupancy && <span> &bull; {occupancy} </span>} */}
              </>
            </Box>
          </Box>
          {room?.price?.show_price&&
            <Box color="purple.700" fontSize="medium">
              <Box as="span" fontSize="xl" fontWeight="semibold">{getLocaleField(room?.price.price)}</Box>
            </Box>
          }
        </Flex>

        <Flex mt="1" alignItems="center" justifyContent="space-between">
          <Text fontWeight="semibold" fontSize="2xl" fontFamily="heading" as="h3" lineHeight="tight" isTruncated>
            {getLocaleField(room?.name)}
          </Text>
        </Flex>
        <Text mt="15px" as="div" noOfLines={3} lineHeight="6" color="gray.600">
          {getLocaleField(room?.shortDescription)}
        </Text>
        <Link href={`/rooms/${room?.slug?.current}`}>
          <Text
            mt="15px"
            as="p"
            noOfLines={3}
            lineHeight="8"
            color="blue.900"
            textTransform="uppercase"
            align="center"
            borderColor="primary.200"
            borderWidth={1}
            borderRadius={4}
          >
            Room Details
          </Text>
        </Link>
      </Box>
    </MotionBox>
  );
};

export { RoomCard };
