import { FC, useMemo } from 'react';
import Link from 'next/link';
import { useLocaleField } from '@/hooks/useLocaleField';
import cn from 'classnames';
import {BsChevronRight} from 'react-icons/bs';
import { Box, Flex } from '@chakra-ui/react';
import styles from './ButtonLink.module.css';
import { ButtonLinkProps } from './ButtonLink.props';

const ButtonLink: FC<ButtonLinkProps> = ({link, type,icon, theme='defaultColor'}) => {
  const {getLocaleField}  =useLocaleField();
  const isPrimary = useMemo<boolean>(() =>  type==='primary',[type]);
  const isSecondary= useMemo<boolean>(() =>  type==='secondary',[type]);
  const isDefault= useMemo<boolean>(() =>  !type,[type]);

  return(
    <Link
      href={link?.url||'/'}
      title={getLocaleField(link?.title)||''}
      target={link?.newTab? '_blank':'_self'}
      className={
        cn(styles.button,
          {[styles.primary]:isPrimary},
          {[styles.secondary]:isSecondary},
          {[styles.default]:isDefault},
          styles[theme]
        )}
    >
      <Flex as="span" alignItems="center">
        {getLocaleField(link?.title)||'Untitled'} <Box as="span" ml={2}>
          {icon|| <BsChevronRight/>
          }
        </Box>
      </Flex>
    </Link>
  );
};

export { ButtonLink };
