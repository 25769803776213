import { ButtonLink } from '@/components/ButtonLink';
import { SectionTitle } from '@/components/SectionTitle';
import { SimpleCarousel } from '@/components/SimpleCarousel';
import { Box, Container, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { EventsProps } from './Events.props';

const Events: FC<EventsProps> = ({data}) => (
  <Box my={20}>
    {data?.sectionTitle&&
    <Box mb={20}>
      <SectionTitle data={data?.sectionTitle} />
    </Box>
    }
    <Container maxW="8xl">
      <SimpleCarousel images={data?.carousel?.images} height={600} width={1400}/>
      <Flex justifyContent="center" mt={15}>
        <ButtonLink link={data?.link} />
      </Flex>
    </Container>
  </Box>
);

export { Events };
