import { SectionTitle } from '@/components/SectionTitle';
import { Box, Container, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import Image from 'next/image';
import { FC } from 'react';
import cn from 'classnames';
import { sanityImage } from '@/utils/sanityImage';
import Link from 'next/link';
import { useLocaleField } from '@/hooks/useLocaleField';
import { ButtonLink } from '@/components/ButtonLink';
import { MeetingsProps } from './Meetings.props';
import styles from './Meetings.module.css';

const Meetings: FC<MeetingsProps> = ({data}) => {
  const {getLocaleField} =useLocaleField();

  return(
    <Box as="section" mt={{ base: '6rem', md: '8rem' }}>
      <SectionTitle data={data?.sectionTitle} />
      <Container maxW="container.xl" mt="25px">
        <Flex justifyContent="space-between" flexDirection={{ base: 'column', md: 'row' }}>
          <Grid
            flex="3"
            templateRows="repeat(3, 1fr)"
            templateColumns="repeat(3, 1fr)"
            gap={4}
          >
            <GridItem rowSpan={1} colSpan={1} position="relative">
              <Image
                src={sanityImage(data?.images?.image1)?.width(270).url()||''}
                className={cn(styles.first_square_image, styles.image)}
                alt="Image 1"
                width={270}
                height={300}
              />
            </GridItem>
            <GridItem colSpan={2} rowSpan={1}>
              <Image
                src={sanityImage(data?.images?.image2)?.width(550).url()||''}
                className={cn(styles.first_square_image, styles.image)}
                alt="Image 2"
                width={550}
                height={300}
              />
            </GridItem>
            <GridItem colSpan={2} rowSpan={2} className={styles.imageGridItem}>
              <div>
                <Image
                  src={sanityImage(data?.images?.image3)?.width(550).url()||''}
                  className={cn(styles.big_image, styles.image)}
                  alt="Image 3"
                  width={550}
                  height={380}
                />
              </div>
            </GridItem>
            <GridItem colSpan={1} rowSpan={1}>
              <Image
                src={sanityImage(data?.images?.image4)?.width(270).url()||''}
                className={cn(styles.first_square_image, styles.image)}
                alt="Image 3"
                width={270}
                height={580}
              />
            </GridItem>
            <GridItem colSpan={1} rowSpan={1}>
              <Image
                src={sanityImage(data?.images?.image5)?.width(270).url()||''}
                className={cn(styles.first_square_image, styles.image)}
                alt="Image 3"
                width={270}
                height={580}
              />
            </GridItem>
          </Grid>
          <Box flex="2" m={{ base: '15px 0 0 0', md: '0px 0px 0px 15px' }} bg="primary-800">
            <Text as="h3" align="center" fontSize="xl" my="5" color="black">{data?.services?.title}</Text>
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
              px={{
                base: '2', sm: '3', md: '0', lg: '5',
              }}
            >
              {data?.services?.serviceItems?.map((serviceItem) => (
                <Flex
                  key={serviceItem?._key}
                  w="33%"
                  px="3"
                  flexDirection="column"
                  justifyContent="top"
                  alignItems="center"
                  mb="5"
                >
                  <Box opacity="0.8" px="2" maxW="100px">
                    <Image
                      src={sanityImage(serviceItem?.icon)?.width(85).url()||''}
                      className={cn(styles.first_square_image, styles.image)}
                      alt={getLocaleField(serviceItem?.title)||'Service icon'}
                      width={85}
                      height={85}
                    />
                  </Box>
                  <Text align="center" as="h5">{getLocaleField(serviceItem?.title)}</Text>
                </Flex>
              ))}
            </Flex>
            <Flex justifyContent="center" my="5">
              <ButtonLink link={data?.services?.cta} theme="blackColor" type="secondary" />
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export { Meetings };
